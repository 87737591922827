/* eslint-disable */
import "./lib/jquery.validate"
import './lib/jquery.maskedinput';
import 'magnific-popup';

export default function modals() {

    $('.form-popup').magnificPopup({
        type: 'inline',
        closeBtnInside: true,
        preloader: false
    });

    $(document).on('click', '.close-modal', function(e) {
        e.preventDefault();
        $.magnificPopup.close();
    });

  const floatFields = document.querySelectorAll('.field');
  floatFields.forEach(field => {
    const input = field.querySelector('input');
    const label = field.querySelector('label');
    if (input && label) {
      input.addEventListener('focus', () => {
      label.classList.add('show');
      });
      input.addEventListener('blur', () => {
        if (!input.value) {
          label.classList.remove('show');
        }
      });
    }
  })

}
