import { gsap } from 'gsap'
import {ScrollScene} from 'scrollscene'
import controls from './controls'

export default function animation() {

  // if(document.querySelector('.tarrifs-images-top')) {
  //   // create a timeline and add a tween
  //   const heroTween = gsap.timeline({ paused: true })

  //   heroTween
  //     .fromTo('.tarrifs-images-logo', 2,{
  //       opacity: .4,
  //       y: -50
  //     }, {
  //       opacity: 1,
  //       y: 0
  //     })
  //     .fromTo('.tarrifs-images-top', 3,{
  //       opacity: 1,
  //       y: 0
  //     }, {
  //       opacity: 0.4,
  //       y: 150
  //     }, '-=2')
  //     .fromTo('.tarrifs-images-bottom', 3,{
  //       scaleY: -1,
  //       opacity: .3,
  //       y: 0
  //     }, {
  //       scaleY: -1,
  //       opacity: 0.7,
  //       y: 150
  //     }, '-=3')

  //   const scrollScene = new ScrollScene({
  //     triggerElement: '.main',
  //     triggerHook: 0.1,
  //     duration: '200%',
  //     gsap: {
  //       timeline: heroTween,
  //       delay: 1
  //     },
  //   })
  // }
  if(document.querySelector('.separate-services')) {
    const logoTween = gsap.timeline({ paused: true })
    logoTween
      .fromTo('.separate-services-logo', 2,{
        opacity: .4,
        scale: 0.6,
        y: -50
      }, {
        opacity: 1,
        scale: 1,
        y: 0
      })

    const separateScene = new ScrollScene({
      triggerElement: '.separate-services',
      triggerHook: 1,
      gsap: {
        timeline: logoTween
      },
    })
  }
  if(document.querySelector('.seo-best')) {
    const seoCool = gsap.timeline({ paused: true })
    seoCool
      .fromTo('.seo-best-image', 1,{
        opacity: .0,
        scale: 0.6,
        y: -200
      }, {
        opacity: 1,
        scale: 1,
        y: 0
      })

    const seoCoolScene = new ScrollScene({
      triggerElement: '.seo-best',
      triggerHook: 1,
      gsap: {
        timeline: seoCool
      },
    })
  }
}
