import Swiper from "swiper";

export default function sliders() {

  (function seoResultSlider() {
    const container = document.querySelector('.seo-result-slider .swiper-container')
    if(!container) return;

    const seoSwiper = new Swiper('.seo-result-slider .swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      }
    });

  })();
  (function myPluginSlider() {
    const mainSlider = document.querySelector('.main-screen-container')
    if(!mainSlider) return;

  const myPlugin = {
    name: "debugger",
    params: {
      debugger: false,
    },
    on: {
      init() {
        if ($(".main-screen-container .swiper-slide-active").hasClass("slide--dark")) {
          $("header.header").addClass("header--light");
        } else {
          $("header.header").removeClass("header--light");
        }
      },
      slideChangeTransitionStart() {
        if ($(".main-screen-container .swiper-slide-active").hasClass("slide--dark")) {
          $("header.header").addClass("header--light");
        } else {
          $("header.header").removeClass("header--light");
        }
        if ($(".main-screen-container .swiper-slide").last().hasClass("swiper-slide-active")) {
          $(".swiper-pagination-progressbar").addClass("last-slide");
        } else {
          $(".swiper-pagination-progressbar").removeClass("last-slide");
        }
      },
      }
    };
    if (window.innerWidth >= 768) {
      Swiper.use(myPlugin);
  
      // Init Swiper
      const swiper = new Swiper(".main-screen-container", {
        roundLengths: true,
        mousewheel: {
          releaseOnEdges: true,
          eventsTarget: $("body"),
        },
        speed: 800,
        pagination: {
          el: ".main-screen-pagination",
          type: "progressbar",
        },
        navigation: {
          nextEl: ".main-screen-next",
          prevEl: ".main-screen-prev",
        },
        debugger: true,
      });
    }
  })();
  (function projectSlider() {
    const swiper = new Swiper(".project-slider", {
      roundLengths: true,
      speed: 800,
      // autoHeight: true,
      pagination: {
        el: ".project-slider-pagination",
        type: "progressbar",
      },
      navigation: {
        nextEl: ".project-slider-next",
        prevEl: ".project-slider-prev",
      },
      on: {
        slideChangeTransitionStart() {
          if ($(".project-slider .swiper-slide").last().hasClass("swiper-slide-active")) {
            setTimeout(() => {
              $(".project-slider .swiper-pagination-progressbar").addClass("last-slide");
            },100)
          } else {
            $(".project-slider .swiper-pagination-progressbar").removeClass("last-slide");
          }
        },
      }
    });
  })();
}
