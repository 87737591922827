/* eslint-disable func-names */
// import { select2 } from 'select2';
import styleSelect from 'styleselect'

export default function controls() {

    $('.header-menu-button').on('click', function() {
        if (!$('.header-menu-button').hasClass('menuOpen')) {
            $('.header-menu-button').addClass('menuOpen');
            $('.header').addClass('menuActive')
            $('.mmenu').addClass('menuActive')
        } else {
            $('.header-menu-button').removeClass('menuOpen');
            $('.header').removeClass('menuActive');
            $('.mmenu').removeClass('menuActive');
        }

    })
    // $('.mmenu-list-nav a').on('click', function() {
    //     $('.mmenu').removeClass('menuOpen')
    //     $('.header').removeClass('menuActive')
    // })


    $('select.js-select').each(function() {
        styleSelect($(this)[0]);
    })


    $('.float-labels input').focusout(function(){

        const value = $(this).val();
        const label = $(this).closest('.field').find('.fly-label');


        if(value === "") {

          label.removeClass('show');

        } else {

          label.addClass('show');

        }

      });




  const contentViewAll = () => {
    const contentBlockList = document.querySelectorAll('.content-widget');
    if (!contentBlockList) return;

    contentBlockList.forEach(item => {
      const content = item.querySelector('.content');
      if (!content) return;

      if (content.clientHeight >= 390) {
        content.classList.add('cropped');

        const moreBtn = document.createElement('button');
        moreBtn.type = 'button';
        moreBtn.textContent = 'Показать все';
        moreBtn.classList.add('cropped__btn');
        content.insertAdjacentElement('afterend', moreBtn);

        const showMore = function() {
          content.classList.remove('cropped');
          moreBtn.removeEventListener('click', showMore);
          moreBtn.remove();
        }

        moreBtn.addEventListener('click', showMore);
      }

    });
  }
  contentViewAll();

    // setTimeout(function() {
    //     $('.video-player').click(function() {
    //         const video = $(this)[0];
    //         const button = $(this).parent()
    //         if (video.paused) {
    //             video.play();
    //             button.addClass('playing')
    //         } else {
    //             video.pause();
    //             button.removeClass('playing')
    //         }
    //     });
    // }, 500)

}
